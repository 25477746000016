var PatientIntake;//needed by DwrScriptSessionListener
define([
    "OApp",
    "OUtils",
    "OEncryption",
    "Patient"
], function(OApp, OUtils, OEncryption) { "use strict";
    function init() {
        if (window.location === window.parent.location) {
            //not in an iframe
            $("#obp").removeClass("hideIfIFrame");
        }
        OUtils.loadPage($("<div/>").appendTo($("body")), "/v-app/patients.html", function() {
           show();
        });
    }
    function show() {
        new Vue({
            i18n : i18n_vue,
            router: OceanRouter,
            store: OceanStore,
            components: {
                OceanComponentHelper: OceanComponentHelper
            },
            el: '#v-patients-app'
        });
    }

    init();
    PatientIntake = {	//Public Methods
        show: show,
    };
    return PatientIntake;
});